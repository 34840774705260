export enum TenantRoutes {
    // Tenant Grouping
    PostTenantGrouping = 'commongateway/TenantOrganization/CreateTenantGroup',
    PutTenantGrouping = 'commongateway/TenantOrganization/UpdateTenantGroup',
    PostPropertyGrouping = 'commongateway/Property/CreatePropertyGroup',
    PutPropertyGrouping = 'commongateway/Property/UpdatePropertyGroup',
    PostResortFinancePropertyGroup = 'commongateway/Property/CreateResortFinancePropertyGroup',
    PutResortFinancePropertyGroup = 'commongateway/Property/UpdateResortFinancePropertyGroup',
    GetTenantOrganization = 'commongateway/TenantOrganization/GetTenantOrganizations',
    GetTenantOrganizationsWithPropertyInfo = 'commongateway/TenantOrganization/GetTenantOrganizationsWithPropertyInfo',
    GetTenantInfobyTenantId = 'commongateway/TenantOrganization/GetTenantByTenantId/{tenantId}',
    GetTenant = 'commongateway/TenantOrganization/GetTenantByContextId/{ContextId}',
    GetTenants = 'commongateway/TenantOrganization/GetTenants',
    GetTenantLists = 'commongateway/TenantOrganization/GetTenantsWithTenantOrganisationCount',
    GetProperty = 'commongateway/Property/GetPropertyByContextId/{ContextId}',
    GetAllProducts = 'commongateway/Product/GetAllProducts',
    GetAllStandAloneProducts = 'commongateway/Product/GetAllStandAloneProducts',
    GetTenantByContextCode = 'commongateway/TenantOrganization/GetTenantByTenantCode?{tenantCode:QueryString}',
    FindDuplicateContextByTenant = 'commongateway/TenantOrganization/FindDuplicateContextByTenant/{parentContextId}?{contextCode:QueryString}',
    FindDuplicateContext = 'commongateway/TenantOrganization/FindDuplicateContext/{ContextId}/{previousContextId}',
    GetAllProductFeatures = 'commongateway/Product/GetAllProductFeatures/{propertyId}',
    //Languages
    CreateLanguage = 'commongateway/Language/AddLanguages',
    UpdateLanguage = 'commongateway/Language/UpdateLanguages',
    GetAllLanguages = 'commongateway/Language/GetAllLanguages',
    DeleteLanguage = 'commongateway/Language/DeleteLanguage/{LanguageId}',
    GetAllCurrency = 'commongateway/Currency/GetAllCurrencies',
    GetPropertyDetails = 'commongateway/Property/GetPropertyDetails',
    GetPropertiesByTenantId = 'commongateway/Property/GetPropertiesByTenantId/{tenantId}',
    GetAllTimeZones = 'commongateway/Property/GetAllTimeZones',
    GetMaximumDecimalPlaces = 'commongateway/Currency/GetMaximumDecimalPlaces',
    GetTenantUsers = "commongateway/User/GetAllTenant",
    GetAllActiveUsers = 'commongateway/User/GetAllActiveUsers/{hours}',
    ProcessSignalR='VisualOne/SignalRService/SignalRNotification/SendNotificationToActiveTenant',

    //Titles
    CreateTitle = 'commongateway/Common/AddTitle',
    UpdateTitle = 'commongateway/Common/UpdateTitle',
    GetAllTitles = 'commongateway/Common/GetAllTitles/{tenantId}/{includeInActive}',
    DeleteTitle = 'commongateway/Common/DeleteTitle',
    //PropertyProduct
    GetPropertyProducts = 'commongateway/PropertyProduct/GetPropertyProducts',
    GetPropertyProductsByPropertyId = 'commongateway/PropertyProduct/GetPropertyProductsByPropertyId/{propertyId}',
    GetProductAndModuleByPropertyId = 'commongateway/PropertyProduct/GetProductAndModuleByPropertyId/{propertyId}',
    CreatePropertyProduct = 'commongateway/PropertyProduct/CreatePropertyProduct',
    UpdatePropertyProduct ='commongateway/PropertyProduct/UpdatePropertyProduct',
    DeletePropertyProduct = 'commongateway/PropertyProduct/DeletePropertyProduct',
    SavePropertyProduct ='commongateway/PropertyProduct/SavePropertyProduct',
    //PropertySetting
    GetPropertySettings = 'commongateway/PropertySetting/GetPropertySettings',
    //Users
    GetAllUsers = 'commongateway/User/GetAllUserbyTenantId/{tenantId}',
    GetUserById = 'commongateway/User/GetUserById/{userId}',
    GetDuplicateUserByName = 'commongateway/User/GetDuplicateUserByName/{userId}?{tenantId:QueryString}&{userName:QueryString}',
    GetDuplicateUserByQuickId = 'commongateway/User/GetDuplicateUserByQuickId/{userId}?{tenantId:QueryString}&{quickId:QueryString}',
    CreateUser = 'commongateway/User/CreateUser',
    UpdateUser = 'commongateway/User/UpdateUser',
    DeleteUser = 'commongateway/User/DeleteUser/{userId}',

    GetADB2CAuthConfigForAllProducts = 'commongateway/Adb2CAuthentication/GetADB2CAuthConfigForAllProducts/{tenantId}',
    //GetADB2CAuthConfig = 'commongateway/Adb2CAuthentication/GetADB2CAuthConfig/{tenantId}/{productId}',

    GetADB2CAuthConfig = 'commongateway/Adb2CAuthentication/GetADB2CAuthConfigByProductId/{tenantId}/{productId}',
    ADB2CLogin = 'commongateway/Adb2CAuthentication/GetADB2CLoginDetails',
    SendEmailADB2CInvite = 'commongateway/Adb2CAuthentication/SendEmailADB2CInvite/{tenantId}',
    UpdateADB2CUserDetails = 'commongateway/Adb2CAuthentication/UpdateADB2CUserDetails/{tenantId}',
    SendEmailADB2CMultipleInvite = 'commongateway/Adb2CAuthentication/SendEmailADB2CMultipleInvite/{tenantId}',
    GetGeneratedADB2CUrlLink = 'commongateway/Adb2CAuthentication/GetGeneratedADB2CUrlLink/{tenantId}/{userId}',
    GetADB2CEnableConfig = 'commongateway/Adb2CAuthentication/GetADB2CEnableConfig/{tenantId}',

    //ADB2C Configuration
    GetAdb2CTenantConfiguration='commongateway/Adb2CTenantConfiguration/GetAdb2CTenantConfiguration/{tenantId}/{adb2cConfigurationName}',
    CreateOrUpdateAdb2CTenantConfiguration='commongateway/Adb2CTenantConfiguration',

    GetPropertyDateFormat = 'commongateway/Language/GetPropertyDateFormat',
    GetPropertyTimeFormat = 'commongateway/Language/GetPropertyTimeFormat',

    //Products
    GetUserProducts = 'commongateway/Product/GetProductsByPropertyId/{propertyId}',
    //Properties
    GetUserProperties = 'commongateway/Property/GetPropertiesByUserId/{userId}',
    //BreakPoints
    GetAllBreakPoint = 'commongateway/user/GetAllUserClaimsAsync',
    GetBreakPoint = 'commongateway/user/GetUserClaimsAsync/{roleID}?{bkpn:QueryString}',
    //UserRole
    GetUserRoles = 'commongateway/UserRole',
    GetActiveUserRoles = 'commongateway/UserRole/GetActiveUserRoles/{includeInActive}',
    GetUserRoleById = 'commongateway/UserRole/GetUserCountsById/{id}',
    CreateUserRole = 'commongateway/UserRole/CreateUserRole' ,
    UpdateUserRole = 'commongateway/UserRole/UpdateUserRole',
    DeleteUserRole = 'commongateway/UserRole/DeleteUserRole',
    //New api CheckDupicateUserRoleExist has been added to validate both role code and role name for Tenant, Golf and Spa
    FindDuplicateUserRole = 'commongateway/UserRole/FindDuplicateUserRole/{propertyId}?{roleCode:QueryString}',
    CheckDupicateUserRoleExist = 'commongateway/UserRole/CheckDupicateUserRoleExist/{propertyId}/{roleCode}/{roleName}',

    //UserRoleConfig
    UpdateUserClaims = 'commongateway/User/UpdateUserClaimsAsync',
    GetUserRoleByPropertyId = 'commongateway/UserRole/GetUserRoleByPropertyId/{propertyId}',

    GetUserRoleByTenantId = 'commongateway/UserRole/GetUserRoleByTenantId/{tenantId}',

    GetActiveUserRolesByPropertyId = 'commongateway/UserRole/GetActiveUserRolesByPropertyId/{propertyId}/{includeInActive}',
    GetUserClaimsByRoleId = 'commongateway/UserRole/GetUserClaims/{RoleId}',
    CopyUserClaims =  'commongateway/User/CopyUserClaimsAsync/{fromRoleId}/{toRoleId}',
    //Login
    ValidateLogin = 'commongateway/Login/ValidateLogin',
    LoginEncrypted = 'commongateway/Property/GetEncLoginDetails',
    GetEncryptKey = 'commongateway/Login/encValue',
    GetBearerToken= 'commongateway/Login/GetBearerToken/{username}',
    PasswordSetting = 'commongateway/Property/GetPasswordSettings/{TenantId}',
    UpdatePasswordSettings = 'commongateway/TenantOrganization/UpdatePasswordSetting',
    SavePassword = 'commongateway/User/SavePassword/{UserId}/{NewPassword}/{TenantId}/{PropertyId}',
    CheckPassword = 'commongateway/User/CheckPasswordExists/{UserId}/{NewPassword}/{TenantId}',
    VerifyPassword = 'commongateway/User/VerifyPassword/{UserId}/{NewPassword}/{TenantId}',
    SavePasswordPost = 'commongateway/User/SavePassword',
    CheckPasswordPut = 'commongateway/User/CheckPasswordExists',
    VerifyPasswordPut = 'commongateway/User/VerifyPassword',
    EnvironmentConfig = 'commongateway/Login/api/Configuration',
    //Session
    CreateSession = 'commongateway/User/session',
    UpdateSession = 'commongateway/User/session/sessionId/{sessionId}',
    //Literals
    CreateLiterals = 'commongateway/Literal/AddLiterals',
    GetAllLiteral = 'commongateway/Literal/GetAllLiteral',
    GetFilteredLiterals = 'commongateway/Literal/GetFilteredLiteras/{filter}',
    UpdateLiteral = 'commongateway/Literal/UpdateLiterals',
    DeleteLiterals = 'commongateway/Literal/DeleteLiteral/{LiteralId}',

    CreateNationality = 'commongateway/Nationality/AddNationality',
    GetAllNationality = 'commongateway/Nationality/GetAllNationality/{tenantId}/{includeInactive}',
    UpdateNationality = 'commongateway/Nationality/UpdateNationality',
    DeleteNationality = 'commongateway/Nationality/DeleteNationality/{Id}',
    GetAllCountry = 'commongateway/Nationality/GetCountries',
    //menu
   // MenuElements = '/commongateway/EntityElement/GetEntityElements?{languageID:QueryString}',
    MenuElements = 'commongateway/EntityElement/GetEntityElements',
    DynamicMenuElements ='commongateway/EntityElement/GetEntityElements/{propertyId}/{productId}',
    AddEntity = 'commongateway/EntityElement/AddEntityElements',
    UpdateEntity = 'commongateway/EntityElement/UpdateEntityElements',
    //NotificationService
    PostTenantConfiguration ='VisualOne/NotificationService/SaveTenantConfiguration',
    GetTenantConfiguration='VisualOne/NotificationService/GetTenantConfiguration/{propertyId}',
    SignalRService='VisualOne/SignalRService',
    //Properties
    GetPropertyInfo = 'commongateway/Property/GetPropertyInfoByPropertyId/{PropertyId}',
     //Property Configuration
     GetPropertyConfiguration='commongateway/PropertyConfiguration/configurationName/{configName}/propertyId/{propertyId}/productId/{productId}',
     CreateOrUpdatePropertyConfiguration='commongateway/PropertyConfiguration',
    //Policies
    GetDataRetentionPolicies = 'commongateway/Policy/GetDataRetentionPolicies?{tenantId:QueryString}',
    GetPolicyConfiguration = 'commongateway/Policy/GetPolicyConfiguration/{PolicyId}',
    GetConsentPolicies = 'commongateway/Policy/GetConsentPolicies/{tenantId}',
    CreatePolicy = 'commongateway/Policy/CreatePolicy',
    UpdatePolicy = 'commongateway/Policy/UpdatePolicy',
    EditPolicyGrid = 'commongateway/Policy/UpdatePolicyGrid',
    GetDropDownValues = 'commongateway/Policy/DropdownValues',
    GetDataGroups  = 'commongateway/Policy/GetDataGroups',
    GetReasons = 'commongateway/Policy/GetReasons/{tenantId}',
    AddReason = 'commongateway/Policy/CreateReason',
    EditReason = 'commongateway/Policy/UpdateReason',
    DeleteReason = 'commongateway/Policy/DeleteReason/{reasonId}/{tenantId}',
    DeleteDataRetentionPolicy = 'commongateway/Policy/DeleteDataRetentionPolicy/{PolicyId}',
    DeletePolicy = 'commongateway/Policy/DeletePolicy/{PolicyId}',
    GetNextListOrder = 'commongateway/Policy/GetNextListOrder/{type}',
    ValidatePolicyCode = 'commongateway/Policy/ValidatePolicyCode/{type}/{policyCode}',
    ValidatePolicyName = 'commongateway/Policy/ValidatePolicyName/{type}/{policyName}',
    GetPolicySetting = 'commongateway/Policy/GetPolicySettingUsingTenantId/{tenantId}',
    SavePolicySetting = 'commongateway/Policy/SavePolicySetting',
    GetProductUsingTenantId = 'commongateway/Policy/GetListOfProductsUsingTenantId/{tenantId}',
    GetDataRetentionPolicyConfiguredFlag = 'commongateway/Policy/GetDataRetentionPolicyConfiguredFlag/{tenantId}',
    GetADB2CAuthConfigByTenantId = 'commongateway/Adb2CAuthentication/GetADB2CAuthConfig/{tenantId}',
    // Properties group
    GetPropertiesByContextIdAndTenantId = 'commongateway/Property/GetPropertiesByContextIdAndTenantId/{contextId}/{tenantId}',
    AddOrRemoveProperties = 'commongateway/property/AddOrRemoveProperties',
    GetPropertyGroupsByTenantId = 'commongateway/TenantOrganization/GetPropertyGroupsByTenantId/{tenantId}',
    // Tenant Configuration
    GetTenantConfigurations= "commongateway/TenantConfiguration/configurationName/{configurationName}",

    // Tenant Setup Configuration
    GetTenantSetUpConfigurations= "commongateway/TenantConfiguration/GetTenantConfiguration",
    CreateOrUpdateTenantSetUpConfiguration = 'commongateway/TenantConfiguration',
    DeleteTenantSetUpConfiguration = 'commongateway/TenantConfiguration/id/{id}',

        //Event Sourcing
        GetAllProperties='commongateway/Property/GetAllProperties',
        GetEventSourcingDatas="commongateway/EventViewer/EventDatas",
        GetEventData="commongateway/EventViewer/Event",
        UpdateEvent="commongateway/EventViewer/UpdateEvent",

   //PlatformSettings

  GetPlatformSettings = 'commongateway/PlatformSettings/GetPlatformSettings/{id}',
  GetPlatformTenant ='commongateway/Platform/SearchPlatFormTenant?{index:QueryString}&{orderBy:QueryString}&{pageSize:QueryString}&{searchText:QueryString}&{sort:QueryString}',

  //JasperSoft
  SynchOrganization = 'commongateway/V1ReportingService/JasperSynchronise/SyncOrganizationsWithJasper?tenantCode={tenantCode}',
  GetJasperTenantSyncStatus='commongateway/V1ReportingService/JasperSynchronise/GetJasperTenantSyncStatus?tenantCode={tenantCode}',
  GetJasperTenantSyncHistory='commongateway/V1ReportingService/JasperSynchronise/GetJasperTenantSyncHistory?tenantCode={tenantCode}',
  //VCart Config
  GetVCartTenantConfiguration = 'commongateway/VCartConfiguration/GetVCartConfiguration/{tenantId}',
  GetAllVCartConfiguration = 'commongateway/VCartConfiguration/GetAllVCartConfiguration/{tenantId}',
  CreateOrUpdateVCartTenantConfiguration = 'commongateway/VCartConfiguration',
  DeleteVCartTenantConfiguration = 'commongateway/VCartConfiguration/id/{id}',


    // support user
    GetUserByTenantId = 'commongateway/User/GetUserByTenantId/{UserName}/{tenantId}',
    GetTenantGroupDetailByProductId = 'TenantOrganization/GetTenantGroupDetailByProductId?productId={productId}',
    GetPropertyDetailsByProductId = 'Property/GetPropertyDetailsByProductId?productId={productId}',

    //Custom Report
    GetCustomReport = 'commongateway/CustomReport/{tenantId}/{propertyId}/{productId}/{reportCode}',
}
